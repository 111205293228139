import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import Header from 'layout/Header';
import Footer from 'layout/Footer';
import "pages/nft.css";
import nft1 from "assets/images/image_nft1.png";
import nft2 from "assets/images/image_nft2.png";
import nft3 from "assets/images/image_nft3.png";
import nftMobile1 from "assets/images/mobile/image_nft1.png";
import nftMobile2 from "assets/images/mobile/image_nft2.png";
import nftMobile3 from "assets/images/mobile/image_nft3.png";

const Nft = ({ setScroll }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const openPage = () => {
        window.open("https://www.torynft.co.kr", "_blank");
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
    <div className="container nft">
        <Header setScroll={setScroll} />
        <div className="wrap">
            <div className="nft-container">
                <div className="nft-section main">
                    {isMobile ? (
                        <img className="nft-main-img" alt="nft" srcSet={nftMobile1 + " 2x"} />
                    ) : (
                        <img className="nft-main-img" alt="nft" src={nft1} />
                    )}
                    
                    <div className="main-text">
                        <p>NFT 갤러리부터 마켓 플레이스까지</p>
                        <p>NFT 통합 플랫폼</p>
                    </div>
                    <button className="main-button" type="button" onClick={openPage}>바로가기</button>
                </div>
                <div className="nft-section section1">
                    {isMobile ? (
                        <img className="content-img" alt="nft" srcSet={nftMobile2 + " 2x"} />
                    ) : (
                        <>
                            <div className="text-container">
                                <p className="title">NFT 3D Gallery</p>
                                <div className="info-text">
                                    <p className="info">나의 NFT 작품을</p>
                                    <p className="info">가상공간에 전시하다</p>
                                </div>
                                <p>NFT를 창작하는 것을 넘어, 나의 작품을 메타버스 가상공간에 전시하고, 작품을 공유 및 홍보할 수 있습니다.</p>
                            </div>
                            <img className="content-img" alt="nft" src={nft2} />
                        </>
                    )}
                </div>
                <div className="nft-section section2">
                    {isMobile ? (
                        <img className="content-img" alt="nft" srcSet={nftMobile3 + " 2x"} />
                    ) : (
                        <>
                            <img className="content-img" alt="nft" src={nft3} />
                            <div className="text-container">
                                <p className="title">NFT Market Place</p>
                                <div className="info-text">
                                    <p className="info">안전하고 자유롭게 NFT 작품을 거래하다</p>
                                </div>
                                <p>NFT를 발행하여 거래하고, 유명 크리에이터의 NFT 작품을 TORYNFT에서 만나보세요.</p>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
        <Footer />
    </div>
    )
}

export default Nft;
