import React, { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { FullPage, Slide } from "react-full-page";
import { useMediaQuery } from "react-responsive";
import { useSwipeable } from "react-swipeable";
import ReactPlayer from "react-player";
import Header from "layout/Header";
import Footer from "layout/Footer";
import data from "data.json";
import "pages/main.css";
import main1 from "assets/images/metaverse.mp4";
import main2 from "assets/images/image_main2.png";
import main3 from "assets/images/nft.mp4";
import imgScroll from "assets/images/image_scroll.png";
import imgScrollBlack from "assets/images/image_scroll_black.png";
import next from "assets/images/icon_next.png";
import prev from "assets/images/icon_prev.png";
import about from "assets/images/image_about.png";
import media1 from "assets/images/image_media1.png";
import media2 from "assets/images/image_media2.png";
import media3 from "assets/images/image_media3.png";
import media4 from "assets/images/image_media4.png";
import media5 from "assets/images/image_media5.png";
import media6 from "assets/images/image_media6.png";
import mediaNon from "assets/images/image_media_non.png";
import contact from "assets/images/image_contact.png";
import fold from "assets/images/image_fold.png";
import unfold from "assets/images/image_unfold.png";
// mobile
import main1Mobile from "assets/images/mobile/metaverse.mp4";
import main3Mobile from "assets/images/mobile/nft.mp4";
import abountMobile1 from "assets/images/mobile/image_about1@2x.png";
import abountMobile2 from "assets/images/mobile/image_about2.png";
import contactMobile from "assets/images/mobile/image_contact.png";
import scrollMobile from "assets/images/mobile/image_scroll.png";
import scrollMobileBlack from "assets/images/mobile/image_scroll_black.png";

const Main = ({ scroll, setScroll }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const fullpageRef = useRef(null);
  const videoRef = useRef(null);
  const backgroundImg = [main1, main2, main3];
  const backgroundMobileImg = [main1Mobile, main2, main3Mobile];
  const titleText = ["Metaverse", "Blockchain", "NFT"];
  const contentText = [
    "상상과 현실의 연결",
    "데이터 분산 처리 기술",
    "대체 불가능한 토큰",
  ];
  const newsImg = [media6, media5, media4, media3, media2, media1];
  const [backgroundNumber, setBackgroundNumber] = useState(1);
  const [isOpen, setIsOpen] = useState(true);
  const mapElement = useRef(null);

  useEffect(() => {
    const { naver } = window;
    if (!mapElement.current || !naver) return;

    // 지도에 표시할 위치의 위도와 경도 좌표를 파라미터로 넣어줍니다.
    const location = new naver.maps.LatLng(
      37.546897399767584,
      127.05196995349421
    );
    const mapOptions = {
      center: location,
      zoom: 15,
      mapDataControl: false,
      zoomControl: true,
      zoomControlOptions: {
        position: naver.maps.Position.TOP_RIGHT,
      },
    };
    const map = new naver.maps.Map(mapElement.current, mapOptions);
    new naver.maps.Marker({
      position: location,
      map,
    });
  }, []);
  useEffect(() => {
    fullpageRef.current.scrollToSlide(scroll);
  }, [scroll]);

  const onBackground = (counter) => {
    if (counter === "inc") {
      if (1 <= backgroundNumber && backgroundNumber < 3) {
        setBackgroundNumber(backgroundNumber + 1);
      }
    } else {
      if (2 <= backgroundNumber && backgroundNumber <= 3) {
        setBackgroundNumber(backgroundNumber - 1);
      }
    }
  };
  const handleYoutube = () => {
    setIsOpen((prev) => !prev);
    if (isOpen) {
      videoRef.current.style.right = "-680px";
    } else {
      videoRef.current.style.right = "0px";
    }
  };
  const onScroll = (to) => {
    fullpageRef.current.scrollToSlide(to);
  };
  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => onBackground("inc"),
    onSwipedRight: (eventData) => onBackground("dec"),
  });
  const openNews = (to) => {
    window.open(to);
  };

  return (
    <div className="container">
      <Header setScroll={setScroll} />
      <FullPage ref={fullpageRef}>
        <Slide>
          <section className="section-common section-area1">
            {backgroundNumber === 2 ? (
              <img
                className="background"
                alt="main1"
                src={backgroundImg[backgroundNumber - 1]}
              />
            ) : isMobile ? (
              <video
                className="background"
                src={backgroundMobileImg[backgroundNumber - 1]}
                autoPlay
                muted
                loop
                data-keepplaying
                playsInline
              ></video>
            ) : (
              <video
                className="background"
                src={backgroundImg[backgroundNumber - 1]}
                autoPlay
                muted
                loop
                data-keepplaying
                playsInline
              ></video>
            )}
            <div className="wrap">
              <div className="main" {...handlers}>
                <p className="image-number">
                  <span>{backgroundNumber}</span>
                  <span className="total-number"> /{backgroundImg.length}</span>
                </p>
                <div className="image-button">
                  <button type="button" onClick={() => onBackground("dec")}>
                    <img alt="prev" src={prev} />
                  </button>
                  <button type="button" onClick={() => onBackground("inc")}>
                    <img alt="next" src={next} />
                  </button>
                </div>
                <p className="title-text">{titleText[backgroundNumber - 1]}</p>
                <p className="text">{contentText[backgroundNumber - 1]}</p>
                <div className="detail-button">
                  <NavLink
                    to={"/" + titleText[backgroundNumber - 1].toLowerCase()}
                  >
                    자세히 보기
                  </NavLink>
                </div>
              </div>
            </div>
            {isMobile ? (
              <img
                className="scroll-img"
                alt="scroll"
                srcSet={scrollMobile + " 2x"}
              />
            ) : (
              <>
                {/* <div className="youtube-container" ref={videoRef}>
                                <button className="btn-youtube" type="button"
                                    aria-expanded={isOpen}
                                    onClick={handleYoutube}>
                                    <img alt="youtube-button" src={isOpen ? fold : unfold} />
                                </button>
                                <div className="youtube-box">
                                    <ReactPlayer
                                        className="player"
                                        url="https://www.youtube.com/watch?v=YP6hyVPhAfE"
                                        width="640px"
                                        height="360px"
                                        playing={true}
                                        muted={true}
                                        controls={true}
                                    />
                                </div>
                            </div> */}
                <img className="scroll-img" alt="scroll" src={imgScroll} />
              </>
            )}
          </section>
        </Slide>
        <Slide>
          <section className="section-common section-area2">
            <div className="wrap">
              {isMobile ? (
                <div className="about">
                  <img
                    className="about-item"
                    alt="about"
                    srcSet={abountMobile1 + " 2x"}
                  />
                  <img
                    className="about-item"
                    alt="about"
                    srcSet={abountMobile2 + " 2x"}
                  />
                </div>
              ) : (
                <img alt="about" src={about} style={{ width: "100%" }} />
              )}
            </div>
            {isMobile ? (
              <img
                className="scroll-img"
                alt="scroll"
                srcSet={scrollMobileBlack + " 2x"}
              />
            ) : (
              <img className="scroll-img" alt="scroll" src={imgScrollBlack} />
            )}
          </section>
        </Slide>
        <Slide>
          <section className="section-common section-area3">
            <div className="wrap">
              <div>
                <div className="media">
                  {data.news
                    .slice(0)
                    .reverse()
                    .map((data, i) => (
                      <div
                        className="media-item"
                        onClick={() => openNews(data.newsUrl)}
                      >
                        <img
                          className="media-img line"
                          alt="media-item"
                          src={newsImg[i]}
                        />
                        <p className="title">{data.title}</p>
                        {!isMobile && <p className="date">{data.regDate}</p>}
                      </div>
                    ))}
                  <div className="media-item no-data">
                    <img
                      className="media-img line"
                      alt="media-item"
                      src={mediaNon}
                    />
                    <p className="title"></p>
                    {!isMobile && <p className="date"></p>}
                  </div>
                  <div className="media-item no-data">
                    <img
                      className="media-img line"
                      alt="media-item"
                      src={mediaNon}
                    />
                    <p className="title"></p>
                    {!isMobile && <p className="date"></p>}
                  </div>
                </div>
                {isMobile ? (
                  <div className="media-button">
                    <button type="button">더보기</button>
                  </div>
                ) : (
                  false
                )}
              </div>
            </div>
            {isMobile ? (
              <img
                className="scroll-img"
                alt="scroll"
                srcSet={scrollMobileBlack + " 2x"}
              />
            ) : (
              <img className="scroll-img" alt="scroll" src={imgScrollBlack} />
            )}
          </section>
        </Slide>
        <Slide>
          <section className="section-common section-area4">
            <div className="wrap">
              <div className="contact">
                <div className="contact-item line">
                  {isMobile ? (
                    <img alt="contact" srcSet={contactMobile + " 2x"} />
                  ) : (
                    <img alt="contact" src={contact} />
                  )}
                </div>
                <div className="contact-item map">
                  <div className="line map">
                    <div ref={mapElement} className="naver-map"></div>
                  </div>
                  <div className="line address">
                    <p>
                      {!isMobile && <span>주소</span>}
                      <span className="address-text">
                        서울 특별시 성동구 성수일로 8길 5, 15층 (성수동2가) (주)
                        TNMETA
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </section>
        </Slide>
      </FullPage>
    </div>
  );
};

export default Main;
