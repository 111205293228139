import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import Sidebar from "layout/Sidebar";
import "layout/header.css";
import tnmeta from "assets/images/image_tnmeta.png";
import tnmetaBlack from "assets/images/image_tnmeta_black.png";
import tnmetaMobile from "assets/images/mobile/image_logo@2x.png";
import tnmetaMobileBlack from "assets/images/mobile/image_logo_black@2x.png";
import hamburger from "assets/images/mobile/icon_list@2x.png";
import hamburgerBlack from "assets/images/mobile/icon_list_black@2x.png";

const Header = ({ setScroll }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const navigate = useNavigate();
    const location = useLocation();
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const updateScroll = () => {
        setScrollPosition(window.scrollY || document.documentElement.scrollTop);
    };
    const toMain = (scroll) => {
        navigate("/");
        setScroll(scroll);
    };
    const handleSidebar = () => {
        setIsOpen(true);
        document.body.className = "is-scroll-rock";
    };
    useEffect(()=>{
        window.addEventListener('scroll', updateScroll);
        return () => {
            window.removeEventListener('scroll', updateScroll);
        };
    }, []);
    return (
    <header className={location.pathname === "/" ? (scrollPosition < 100 ? "header" : "header dark") : "header dark detail"}>
        <div className="inner">
            <button type="button" className="logo" onClick={() => toMain(0)}>
                {isMobile ? (
                    <img className="tnmeta-logo" alt="tnmeta-logo" srcSet={location.pathname === "/" && scrollPosition < 100 ? tnmetaMobile + " 2x" : tnmetaMobileBlack + " 2x"} />
                ) : (
                    <img className="tnmeta-logo" alt="tnmeta-logo" src={location.pathname === "/" && scrollPosition < 100 ? tnmeta : tnmetaBlack} />
                )}
            </button>
            <button type="button" className="header-menu" 
                                aria-haspopup="true" 
                                aria-expanded={isOpen} 
                                aria-controls="nav" 
                                aria-label="open menu"
                                onClick={handleSidebar}>
                <img alt="hamburger" srcSet={location.pathname === "/" && scrollPosition < 100 ? hamburger + " 2x" : hamburgerBlack + " 2x"} />
            </button>
            <nav className="nav">
                <ul className="nav-depth">
                    <li className="company">
                        <button type="button" className="nav-link">Company</button>
                        <div className="nav-detail">
                            <div>
                                <div className="nav-item"><NavLink to="/metaverse">Metaverse</NavLink></div>
                                <div className="nav-item"><NavLink to="/blockchain">Blockchain</NavLink></div>
                                <div className="nav-item"><NavLink to="/nft">NFT</NavLink></div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <button type="button" className="nav-link" onClick={() => toMain(1)}>About</button>
                    </li>
                    <li>
                        <button type="button" className="nav-link" onClick={() => toMain(2)}>Media</button>
                    </li>
                    <li>
                        <button type="button" className="nav-link" onClick={() => toMain(3)}>Contact</button>
                    </li>
                    <li>
                        <NavLink to="/recruit/job" className="nav-link">Recruit</NavLink>
                    </li>
                </ul>
                <Sidebar setIsOpen={setIsOpen} />
            </nav>
        </div>
    </header>
    )
}

export default Header;
