import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import "pages/notFound.css";
import errorDesktop from "assets/images/error_image_desktop.png";
import errorMobile from "assets/images/mobile/error_image_mobile.png";

const NotFound = () => {
    
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ maxWidth: 767 });
    
    return (
    <div className="notfound-container">
        <div className="notfound-wrap">
            <img className="error-img" alt="error-img" src={isMobile ? errorMobile : errorDesktop} />
            <button className="btn-main" type="button" onClick={() => navigate("/")}>메인으로</button>
        </div>
    </div>
    )
}

export default NotFound;
