import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useSwipeable } from 'react-swipeable';
import Header from 'layout/Header';
import Footer from "layout/Footer";
import "pages/metaverse.css";
import torymetaLogo from "assets/images/image_metaverse1.png";
import metaverse2 from "assets/images/image_metaverse2.png";
import metaverse3 from "assets/images/image_metaverse3.png";
import metaverse4 from "assets/images/image_metaverse4.png";
import metaverse5 from "assets/images/image_metaverse5.png";
import metaverse6 from "assets/images/image_metaverse6.png";
import line from "assets/images/image_line.png";
import QRCode from 'react-qr-code';

// mobile
import torymetaLogoMobile from "assets/images/mobile/image_metaverse1.png";
import metaverseMobile2 from "assets/images/mobile/image_metaverse2@2x.png";
import metaverseMobile3 from "assets/images/mobile/image_metaverse3.png";
import metaverseMobile4 from "assets/images/mobile/image_metaverse4@2x.png";
import metaverseMobile5 from "assets/images/mobile/image_metaverse5.png";
import lineMobile from "assets/images/mobile/image_line.png";
import torymeta1 from "assets/images/mobile/torymeta1@2x.png";
import torymeta2 from "assets/images/mobile/torymeta2@2x.png";
import torymeta3 from "assets/images/mobile/torymeta3@2x.png";
import torymeta4 from "assets/images/mobile/torymeta4@2x.png";

const Metaverse = ({ setScroll }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const dynamicLink = "https://torymeta.page.link/jofZ";
    const torymetaImg = [torymeta1, torymeta2, torymeta3, torymeta4];
    const torymetaText = ["2D기반 위치 공유", "커뮤니케이션 플랫폼", "NFT 마켓 플레이스", "정보성 컨텐츠"]
    const [imageNumber, setImageNumber] = useState(1);
    const onImage = (counter) => {
        if(counter === "inc") {
            if(1 <= imageNumber && imageNumber < 4) {
                setImageNumber(imageNumber + 1);
            }
        } else {
            if(2 <= imageNumber && imageNumber <= 4) {
                setImageNumber(imageNumber - 1);
            }
        }
    };
    const openPage = () => {
        window.open("https://www.torymeta.co.kr", "_blank");
    };
    const handlers = useSwipeable({
        onSwipedLeft: (eventData) => onImage("inc"),
        onSwipedRight: (eventData) => onImage("dec")
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
    <div className="container metaverse">
        <Header setScroll={setScroll} />
        <div className="wrap">
            <div className="metaverse-container">
                <div className="metaverse-wrap">
                    <div className="content">
                        {isMobile ? (
                            <img className="torymeta-logo" alt="torymeta-logo" srcSet={torymetaLogoMobile + " 2x"} />
                        ) : (
                            <img className="torymeta-logo" alt="torymeta-logo" src={torymetaLogo} />
                        )}
                        <p className={isMobile ? "text-main pt-40" : "text-main pt-50"}>다양하고 즐거운</p>
                        <p className="text-main">캠퍼스 생활을 즐겨보세요.</p>
                        <p className={isMobile ? "text-info pt-20" : "text-info pt-30"}>실시간 위치 공유, 3D 메타 캠퍼스, 토리톡, NFT 마켓 플레이스, 게시판, 이벤트 등 다양한 기능과 즐거움을 만날 수 있습니다.</p>
                        {!isMobile && (
                        <div className="content-qr">
                            <div className="qr-box">
                                <p>QR코드 다운로드</p>
                                <div className="line">
                                <QRCode value={dynamicLink} size={100} />
                                </div>
                            </div>
                            <div className="button-box">
                                <button type="button" onClick={openPage}>바로가기</button>
                            </div>
                        </div>
                        )}
                    </div>
                    <div className="content">
                        {isMobile ? (
                            <div className="button-box column">
                                <img className="metaverse-img" alt="metaverse-tory" srcSet={metaverseMobile2 + " 2x"} />
                                <button type="button" onClick={openPage}>바로가기</button>
                            </div>
                        ) : (
                            <img className="metaverse-img" alt="metaverse-tory" src={metaverse2} />
                        )}
                    </div>
                </div>
                <div className="metaverse-section section2">
                    <div className="title">
                        {!isMobile && <img className="text-line" alt="text-line" src={line} /> }
                        <div>
                            <p className="title-main">C2E (Communicate to Earn)</p>
                            {isMobile && <img className="text-line" alt="text-line" srcSet={lineMobile + " 2x"} /> }
                            <p className="title-sub">커뮤니티 활동에 대한 보상을 제공하는 대학생 참여형 플랫폼</p>
                        </div>
                    </div>
                    <div className="content">
                        {isMobile ? (
                            <img alt="content" srcSet={metaverseMobile3 + " 2x"} />
                        ) : (
                            <img alt="content" src={metaverse3} />
                        )}
                    </div>
                </div>
                <div className="metaverse-section section3">
                    <div className="title">
                        {!isMobile && <img className="text-line" alt="text-line" src={line} /> }
                        <div>
                            <p className="title-main">서비스 상세</p>
                            {isMobile && <img className="text-line" alt="text-line" srcSet={lineMobile + " 2x"} /> }
                            <p className="title-sub">5가지의 다양한 카테고리를 통해 다양한 캠퍼스 활동을 할 수 있는 플랫폼</p>
                        </div>
                    </div>
                    <div className="content">
                        {isMobile ? (
                            <>
                                <img className="metaverse-img4" alt="content" srcSet={metaverseMobile4 + " 2x"} />
                                <p className="img-text">3D 메타 캠퍼스</p>
                                <div className="move-image">
                                    <img className="move-img" alt="metacampus" srcSet={torymetaImg[imageNumber-1] + " 2x"} {...handlers} />
                                    <p className="img-text">{torymetaText[imageNumber-1]}</p>
                                    <div className="move-btn">
                                        <button type="button" className="btn-prev" onClick={() => onImage("dec")}></button>
                                        <div className="nav-img">{imageNumber} / {torymetaImg.length}</div>
                                        <button type="button" className="btn-next" onClick={() => onImage("inc")}></button>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                            <img className="metaverse-img4" alt="content" src={metaverse4} />
                            <img alt="content" src={metaverse5} />
                            </>
                        )}
                        
                    </div>
                </div>
                <div className="metaverse-section section4">
                    <div className="title">
                        {!isMobile && <img className="text-line" alt="text-line" src={line} /> }
                        <div>
                            <p className="title-main">함께하는 대학교</p>
                            {isMobile && <img className="text-line" alt="text-line" srcSet={lineMobile + " 2x"} /> }
                            <p className="title-sub">‘토리메타’서비스와 함께하는 대학교</p>
                        </div>
                    </div>
                    <div className="content">
                        {isMobile ? (
                            <img alt="content" srcSet={metaverseMobile5 + " 2x"} />
                        ) : (
                            <img alt="content" src={metaverse6} />
                        )}
                    </div>
                </div>
            </div>
            
        </div>
        <Footer />
    </div>
    )
}

export default Metaverse;
