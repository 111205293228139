import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import Header from 'layout/Header';
import Footer from 'layout/Footer';
import "pages/blockchain.css";
import blockchain1 from "assets/images/image_blockchain1.png";
import blockchain2 from "assets/images/image_blockchain2.png";
import blockchain3 from "assets/images/image_blockchain3.png";
import line from "assets/images/image_line.png";

// mobile
import blockchainMobile1 from "assets/images/mobile/image_blockchain1.png";
import blockchainMobile2 from "assets/images/mobile/image_blockchain2.png";
import blockchainMobile3 from "assets/images/mobile/image_blockchain3.png";
import lineMobile from "assets/images/mobile/image_line.png";

const Blockchain = ({ setScroll }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
    <div className="container blockchain">
        <Header setScroll={setScroll} />
        <div className="wrap">
            <div className="blockchain-container">
                <div className="blockchain-section main">
                    <p className="main-title">TNMETA 블록체인 서비스</p>
                    <p className="main-text">신뢰 · 안전 · 사용자 중심을 바탕으로 블록체인 기반의 기술력 구축</p>
                    {isMobile ? (
                        <img className="bc-main-img" alt="blockchain" srcSet={blockchainMobile1 + " 2x"} />
                    ) : (
                        <img className="bc-main-img" alt="blockchain" src={blockchain1} />
                    )}
                    
                </div>
                <div className="blockchain-section section1">
                    <div className="title">
                        {!isMobile && <img className="text-line" alt="text-line" src={line} /> }
                        <div>
                            <p className="title-main">서비스 특장점</p>
                            {isMobile && <img className="text-line" alt="text-line" srcSet={lineMobile + " 2x"} /> }
                            <p className="title-sub">TNMETA 블록체인 서비스의 특장점</p>
                        </div>
                    </div>
                    <div className="content">
                        {isMobile ? (
                            <img className="content-img" alt="content" srcSet={blockchainMobile2 + " 2x"} />
                        ) : (
                            <>
                                <img className="content-img" alt="content" src={blockchain2} />
                                <div className="text-container">
                                    <div className="text-box">
                                        <p className="number">01</p>
                                        <p className="title">신뢰성</p>
                                        <p>블록체인은 블록들이 체인 형태로 연결되어있고, 
                                            그것들이 서로 유기적이기 때문에 그 안의 정보변조가 불가능하다. 
                                            따라서, 사용사는 블록체인에 저장되어있는 정보를 신뢰할 수 있다.</p>
                                    </div>
                                    <div className="text-box">
                                        <p className="number">02</p>
                                        <p className="title">안전성</p>
                                        <p>중앙 서버 없이 P2P 분산 서버에서 구동되는 시스템으로 
                                            모든 데이터들이 복사되어 노드들 PC에 저장되어 있다.
                                            시스템을 공격하기 위해서는 절반이상의 노드를 해킹해야하며 
                                            그것은 현실적으로 불가능하기에 자산 관리에 안정적이다.</p>
                                    </div>
                                    <div className="text-box">
                                        <p className="number">03</p>
                                        <p className="title">WEB3</p>
                                        <p>블록체인 기반의 토큰 이코노미를 구축, 사용자 중심의 인터넷 기술 서비스를 제공합니다.</p>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="blockchain-section section2">
                    <div className="title">
                        {!isMobile && <img className="text-line" alt="text-line" src={line} /> }
                        <div>
                            <p className="title-main">서비스 구성도</p>
                            {isMobile && <img className="text-line" alt="text-line" srcSet={lineMobile + " 2x"} /> }
                            <p className="title-sub">TNMETA 블록체인 서비스의 구성도</p>
                        </div>
                    </div>
                    <div className="content">
                        {isMobile ? (
                            <img className="content-img" alt="content-img" srcSet={blockchainMobile3 + " 2x"} />
                        ) : (
                            <img className="content-img" alt="content-img" src={blockchain3} />
                        )}
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
    )
}

export default Blockchain;
