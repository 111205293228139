import React from 'react';
import { NavLink } from 'react-router-dom';
import "layout/sidebar.css";
import tnmetaMobileBlack from "assets/images/mobile/image_logo_black@2x.png";
import close from "assets/images/mobile/icon_close@2x.png";

const Sidebar = ({ setIsOpen }) => {
    const closeSidebar = () => {
        setIsOpen(false);
        document.body.className = "";
    };
    return (
    <div className="nav-mobile">
        <div className="nav-mobile-header">
            <NavLink to="/" className="nav-mobile-logo">
                <img className="logo" alt="logo" srcSet={tnmetaMobileBlack + " 2x"} />
            </NavLink>
            <button type="button" className="nav-mobile-close" aria-label="close menu" onClick={closeSidebar}>
                <img alt="close" srcSet={close + " 2x"} />
            </button>
        </div>
        <ul className="nav-mobile-list">
            <li className="nav-mobile-item">
                <NavLink to="/metaverse" className="nav-mobile-link">Metaverse</NavLink>
            </li>
            <li className="nav-mobile-item">
                <NavLink to="/blockchain" className="nav-mobile-link">Blockchain</NavLink>
            </li>
            <li className="nav-mobile-item">
                <NavLink to="/nft" className="nav-mobile-link">NFT</NavLink>
            </li>
            <li className="nav-mobile-item">
                <NavLink to="/recruit/job" className="nav-mobile-link">Recruit</NavLink>
            </li>
        </ul>
    </div>
    )
}

export default Sidebar;
