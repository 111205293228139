import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useSwipeable } from 'react-swipeable';
import Header from 'layout/Header';
import Footer from "layout/Footer";
import "pages/recruit.css";
import job1 from "assets/images/image_jobs1.png";
import job2 from "assets/images/image_jobs2.png";
import job3 from "assets/images/image_jobs3.png";
import job4 from "assets/images/image_jobs4.png";
import line from "assets/images/image_line.png";

// mobile
import jobMobile1 from "assets/images/mobile/image_jobs1@2x.png";
import jobMobile2 from "assets/images/mobile/image_jobs2@2x.png";
import jobMobile3 from "assets/images/mobile/image_jobs3@2x.png";
import jobMobile4 from "assets/images/mobile/image_jobs4@2x.png";
import jobMobile5 from "assets/images/mobile/image_jobs5@2x.png";
import jobMobile6 from "assets/images/mobile/image_jobs6@2x.png";
import jobMobile7 from "assets/images/mobile/image_jobs7@2x.png";
import lineMobile from "assets/images/mobile/image_line.png";

const Recruit = ({ setScroll }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const { type } = useParams();
    const navigate = useNavigate();
    const jobImg = [jobMobile4, jobMobile5, jobMobile6, jobMobile7];
    const [imageNumber, setImageNumber] = useState(1);
    const onImage = (counter) => {
        if(counter === "inc") {
            if(1 <= imageNumber && imageNumber < 4) {
                setImageNumber(imageNumber + 1);
            }
        } else {
            if(2 <= imageNumber && imageNumber <= 4) {
                setImageNumber(imageNumber - 1);
            }
        }
    };
    const handlers = useSwipeable({
        onSwipedLeft: (eventData) => onImage("inc"),
        onSwipedRight: (eventData) => onImage("dec")
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
    <div className="container jobs">
        <Header setScroll={setScroll} />
        <div className="wrap">
            <div className="jobs-container">
                <div className="jobs-section main">
                    {isMobile && <p className="main-title">TNMETA 인재 채용</p>}
                    <p className="main-text">TNMeta는 모두에 <span className="color">열려있습니다.</span></p>
                    <p className="main-info">최대한 신속하게 채용 프로세스를 진행하여, 지원자의 기다림을 최소화하겠습니다.</p>
                    {isMobile ? (
                        <img className="main-img" alt="jobs" srcSet={jobMobile1 + " 2x"} />
                    ) : (
                        <img className="main-img" alt="jobs" src={job1} />
                    )}
                </div>
                <div className="jobs-button">
                    <button type="button" className={type !== "welfare" ? "btn-jobs active" : "btn-jobs"} onClick={() => navigate("/recruit/job")}>영입절차</button>
                    <button type="button" className={type === "welfare" ? "btn-jobs active" : "btn-jobs"} onClick={() => navigate("/recruit/welfare")}>복지혜택</button>                  
                </div>
                {type === "welfare" ? (
                    <div className="jobs-section section1">
                        {isMobile ? (
                            <>
                                <div className="move-image">
                                    <img className="move-img" alt="metacampus" srcSet={jobImg[imageNumber-1] + " 2x"} {...handlers} />
                                    <div className="move-btn">
                                        <button type="button" className="btn-prev" onClick={() => onImage("dec")}></button>
                                        <div className="nav-img">{imageNumber} / {jobImg.length}</div>
                                        <button type="button" className="btn-next" onClick={() => onImage("inc")}></button>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <img className="jobs-img" alt="jobs-img" src={job4} />
                        )}
                    </div>
                ) : (
                    <>
                        <div className="jobs-section section1">
                            <div className="title">
                                {!isMobile && <img className="text-line" alt="text-line" src={line} /> }
                                <div>
                                    <p className="title-main">채용 프로세스</p>
                                    {isMobile && <img className="text-line" alt="text-line" srcSet={lineMobile + " 2x"} /> }
                                    <p className="title-sub">모든 전형의 합격 여부는 개별적으로 안내해드립니다.</p>
                                </div>
                            </div>
                            {isMobile ? (
                                <img className="jobs-img" alt="jobs-img" srcSet={jobMobile2 + " 2x"} />
                            ) : (
                                <img className="jobs-img" alt="jobs-img" src={job2} />
                            )}
                        </div>
                        <div className="jobs-section section2">
                            <div className="title">
                                {!isMobile && <img className="text-line" alt="text-line" src={line} /> }
                                <div>
                                    <p className="title-main">오피스 위치</p>
                                    {isMobile && <img className="text-line" alt="text-line" srcSet={lineMobile + " 2x"} /> }
                                    <p className="title-sub">업무는 연구소에서 진행됩니다.</p>
                                </div>
                            </div>
                            {isMobile ? (
                                <img className="jobs-img" alt="jobs-img" srcSet={jobMobile3 + " 2x"} />
                            ) : (
                                <img className="jobs-img" alt="jobs-img" src={job3} />
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
        <Footer />
    </div>
    )
}

export default Recruit;
