import React from "react";
import "layout/footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="wrap">
        <p>
          서울 성동구 성수일로 8길 5, 17층 1701호(성수동2가)(주)티앤아이체인랩스
        </p>
        <p>
          사업자등록번호 895-86-02787 | 호스팅 서비스 제공자 :
          (주)티앤아이체인랩스 | Email : help@ticl.kr
        </p>
        <p className="copyrights">
          Copyrights ⓒ2022 TNI ChainLabs Inc. All rights reserved. Contact Us
        </p>
      </div>
    </footer>
  );
};

export default Footer;
