import React, { useState, useEffect } from "react";
import './App.css';
import { Routes, Route, useLocation } from "react-router-dom";
import Main from 'pages/Main';
import Metaverse from 'pages/Metaverse';
import Blockchain from 'pages/Blockchain';
import Nft from 'pages/Nft';
import Recruit from "pages/Recruit";
import NotFound from "pages/NotFound";

function App() {
  // const { pathname } = useLocation();
  const [scroll, setScroll] = useState(0);
  // useEffect(() => {
  //   window.scrollTo(0,0);
  // }, [pathname]);
  return (
    <div className="App">
      <Routes>
        {/* 404page */}
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Main scroll={scroll} setScroll={setScroll} />} />
        <Route path="/metaverse" element={<Metaverse setScroll={setScroll} />} />
        <Route path="/blockchain" element={<Blockchain setScroll={setScroll} />} />
        <Route path="/nft" element={<Nft setScroll={setScroll} />} />
        <Route path="/recruit/:type" element={<Recruit setScroll={setScroll} />} />
      </Routes>
    </div>
  );
}

export default App;
